var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppBack'),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('AppDateTime',{attrs:{"no-value-to-custom-elem":true,"format":"YYYY-MM-DD","formatted":"YYYY-MM-DD","range":"","color":"black","auto-close":""},model:{value:(_vm.rangeDate),callback:function ($$v) {_vm.rangeDate=$$v},expression:"rangeDate"}},[_c('v-text-field',{attrs:{"disabled":_vm.loading,"name":"range","label":"Range","type":"text","append-icon":"mdi-calendar","filled":"","dense":"","readonly":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.loading,"items":_vm.listUser,"item-text":"name","item-value":"id","label":"ScannedBy","chips":"","deletable-chips":"","multiple":"","dense":"","filled":""},on:{"change":function($event){return _vm.initialize()}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectedItems.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= 1)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedItems.length - 2)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cari No. Surat Jalan","filled":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('AppActionReport',{attrs:{"loading":_vm.loading,"items":_vm.items},on:{"emitOnAction":_vm.onAction}}),_c('v-data-table',{attrs:{"loading":_vm.loading,"loader-height":"2","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":"createdAt","items-per-page":-1,"sort-desc":true,"dense":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(item.createdAt)?_c('span',[_vm._v(_vm._s(_vm._f("datetime2")(item.createdAt)))]):_vm._e()]}},{key:"item.scan2",fn:function(ref){
var item = ref.item;
return [(item.scan2)?_c('span',[_vm._v(_vm._s(_vm._f("datetime2")(item.scan2)))]):_vm._e(),(item.scanned2ByName)?_c('span',[_c('br'),_vm._v(" by "+_vm._s(item.scanned2ByName))]):_vm._e()]}},{key:"item.scan3",fn:function(ref){
var item = ref.item;
return [(item.scan3)?_c('span',[_vm._v(_vm._s(_vm._f("datetime2")(item.scan3)))]):_vm._e(),(item.scanned3ByName)?_c('span',[_c('br'),_vm._v(" by "+_vm._s(item.scanned3ByName))]):_vm._e()]}},{key:"item.scan4",fn:function(ref){
var item = ref.item;
return [(item.scan4)?_c('span',[_vm._v(_vm._s(_vm._f("datetime2")(item.scan4)))]):_vm._e(),(item.scanned4ByName)?_c('span',[_c('br'),_vm._v(" by "+_vm._s(item.scanned4ByName))]):_vm._e()]}},{key:"item.scan5",fn:function(ref){
var item = ref.item;
return [(item.scan5)?_c('span',[_vm._v(_vm._s(_vm._f("datetime2")(item.scan5)))]):_vm._e(),(item.scanned5ByName)?_c('span',[_c('br'),_vm._v(" by "+_vm._s(item.scanned5ByName))]):_vm._e()]}},{key:"item.scan6",fn:function(ref){
var item = ref.item;
return [(item.scan6)?_c('span',[_vm._v(_vm._s(_vm._f("datetime2")(item.scan6)))]):_vm._e(),(item.scanned6ByName)?_c('span',[_c('br'),_vm._v(" by "+_vm._s(item.scanned6ByName))]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }