

















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { SuratJalan as ApiSuratJalan } from '@/api/report'
import { ExportToCsv, ExportToPdf } from '@/plugins/export-file'
import ActionReport from '@/components/ActionReport.vue'

@Component({
  components: {
    'AppActionReport': ActionReport
  }
})
export default class SuratJalan extends Vue {
  //#region COMPUTED
  get value() {
    return this.formatDate(this.rangeDate.start) + ' ~ ' + this.formatDate(this.rangeDate.end)
  }

  get listUser() {
    return this.$store.getters.sharedTrackingUserList
  }
  get selectAllItem() {
    return this.selectedItems.length === this.listUser.length
  }
  get likeSomeItem() {
    return this.selectedItems.length > 0 && !this.selectAllItem
  }
  get icon() {
    if (this.selectAllItem) return 'mdi-close-box'
    if (this.likeSomeItem) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }
  //#endregion
  //#region DATA
  loading:boolean = false
  headers:Array<object> = [
    { text: 'Date', align: 'start', value: 'createdAt' },
    { text: 'QR', value: 'barcode' },
    { text: 'Picker', value: 'scan2' },
    { text: 'Checker', value: 'scan3' },
    { text: 'Packing', value: 'scan4' },
    { text: 'Kurir (IN)', value: 'scan5' },
    { text: 'Kurir (OUT)', value: 'scan6' },
  ]
  rangeDate:any = {
    start: this.$options.filters!.startOfMonth(),
    end: this.$options.filters!.fdate(new Date())
  }
  items:any = []
  selectedItems:Array<number> = []
  search:any = null
  expanded:any = []
  //#endregion
  //#region WATCH
  @Watch('rangeDate')
  rangeDateChanged(newVal:any) {
    this.initialize()
  }
  //#endregion
  //#region METHODS
  async initialize() {
    if (this.rangeDate.start && this.rangeDate.end) {
      this.loading = true
      try {
        const payload = {
          dateFrom: this.rangeDate.start,
          dateUntil: this.rangeDate.end,
          listUser: this.selectedItems.sort((a:any, b:any) => a - b).join(',')
        }
        const { data } = await ApiSuratJalan(payload)
        this.items = data.data
        this.loading = false
      } catch (e:any) {
        this.$store.dispatch('setError', e.response.data)
      } finally {
        setTimeout(() => this.loading = false, 500)
      }
    }
  }
  onAction(item:any) {
  }
  formatDate(val:any) {
    return this.$options.filters?.custom('DD MMM YYYY', val)
  }
  toggle() {
    this.$nextTick(() => {
      if (this.selectAllItem) {
        this.selectedItems = []
      } else {
        this.selectedItems = this.listUser.map((x:any) => x.id).slice()
        this.initialize()
      }
    })
  }
  //#endregion
  //#region CREATED
  created() {
    this.$store.dispatch('ReadSharedTrackingUserList')
  }
  //#endregion
  //#region MOUNTED
  mounted() {
    this.initialize()
  }
  //#endregion
}
